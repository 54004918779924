import * as React from "react";
import { useState } from "react";

import { Helmet } from "react-helmet";

import { navigate } from "gatsby";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDribbble,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCode,
  faFilm,
  faVectorSquare,
} from "@fortawesome/free-solid-svg-icons";

import avatar from "../images/avatar.svg";
import logo from "../images/Logo_DarkBG.svg";

import projects from "../projects";

// markup
const IndexPage = () => {
  const [currentCategory, setCategory] = useState("All");

  const projectsByCategory = projects.filter((project) =>
    currentCategory === "All" ? true : project.category === currentCategory
  );

  return (
    <main className="bg-light">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jan Duras - Portfolio</title>

        <meta name="theme-color" content="#FFC132"></meta>

        <meta
          name="description"
          content="Portfolio of Jan Duras - Graphic designer, filmmaker and passionate mountain biker who's willing to help you out with your next creative project."
        />
      </Helmet>

      <header className="bg-dark">
        <div className="container mx-auto font-sans flex space-x-4 pt-8 px-8">
          <div className="container flex items-center flex-wrap">
            <div>
              <img alt="Jan Duras Logo" className="h-16" src={logo} />
              <h1 className="text-7xl text-light font-semibold mt-2">
                Hi, I am <span className="text-yellow text-8xl">Ján Ďuraš</span>
              </h1>
              <p className="text-3xl text-light text-opacity-75 my-5">
                Graphic designer, filmmaker and passionate mountain biker
              </p>
            </div>
          </div>
          <div className="container hidden lg:block">
            <img
              alt="Jan Duras Avatar"
              className="h-56 w-full object-cover sm:w-full sm:h-full md:w-full md:h-full lg:w-full lg:h-full"
              src={avatar}
            />
          </div>
        </div>
      </header>

      <main>
        <div className="container mx-auto my-7">
          <div className="flex justify-center flex-wrap mb-6">
            <button
              onClick={() => setCategory("All")}
              type="button"
              className={
                currentCategory === "All"
                  ? "transition-all px-3 mr-2 text-base text-light rounded-md shadow-sm font-medium bg-blue hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
                  : "transition-all px-3 mr-2 text-base text-dark rounded-md shadow-sm font-medium bg-grey hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
              }
              data-cy="categoryBtnAll"
            >
              All
            </button>
            <button
              onClick={() => setCategory("Design")}
              type="button"
              className={
                currentCategory === "Design"
                  ? "transition-all px-3 mr-2 text-base text-light rounded-md shadow-sm font-medium bg-blue hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
                  : "transition-all px-3 mr-2 text-base text-dark rounded-md shadow-sm font-medium bg-grey hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
              }
              data-cy="categoryBtnDesign"
            >
              <FontAwesomeIcon className="text-sm" icon={faVectorSquare} />{" "}
              Design
            </button>
            <button
              onClick={() => setCategory("Filmmaking")}
              type="button"
              className={
                currentCategory === "Filmmaking"
                  ? "transition-all px-3 mr-2 text-base text-light rounded-md shadow-sm font-medium bg-blue hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
                  : "transition-all px-3 mr-2 text-base text-dark rounded-md shadow-sm font-medium bg-grey hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
              }
              data-cy="categoryBtnFilmmaking"
            >
              <FontAwesomeIcon className="text-sm" icon={faFilm} /> Filmmaking
            </button>
            <button
              onClick={() => setCategory("Web development")}
              type="button"
              className={
                currentCategory === "Web development"
                  ? "transition-all px-3 mr-2 text-base text-light rounded-md shadow-sm font-medium bg-blue hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
                  : "transition-all px-3 mr-2 text-base text-dark rounded-md shadow-sm font-medium bg-grey hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
              }
              data-cy="categoryBtnWebDevelopment"
            >
              <FontAwesomeIcon className="text-sm" icon={faCode} /> Web
              development
            </button>
            <button
              onClick={() => setCategory("Other")}
              type="button"
              className={
                currentCategory === "Other"
                  ? "transition-all px-3 mr-2 text-base text-light rounded-md shadow-sm font-medium bg-blue hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
                  : "transition-all px-3 mr-2 text-base text-dark rounded-md shadow-sm font-medium bg-grey hover:bg-blue hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue mt-2"
              }
              data-cy="categoryBtnOther"
            >
              Other
            </button>
          </div>

          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry gutter={16}>
              {projectsByCategory.map((project) => (
                <img
                  className="rounded"
                  key={project.key}
                  src={project.src}
                  style={{ width: "100%", display: "block", cursor: "pointer" }}
                  alt={project.name}
                  onClick={() => navigate("/project?name=" + project.key)}
                  data-cy="homepageProject"
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </main>

      <footer>
        <div className="container mx-auto text-center mt-7 bg-light py-7 rounded-t-lg border border-grey">
          <p className="text-dark text-xl">Feel free to contact me</p>
          <span className="text-4xl font-semibold">jan@duras.me</span>
          <div className="mt-7">
            <button
              type="button"
              className="transition-all px-4 py-3 mr-2 text-4xl rounded-md shadow-sm font-medium text-dribbble bg-dribbble bg-opacity-10 hover:bg-dribbble hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue rounded-full"
            >
              <a
                href="https://dribbble.com/durasjan"
                rel="noopener noreferrer"
                data-cy="dribbleLink"
              >
                <FontAwesomeIcon icon={faDribbble} />
              </a>
            </button>
            <button
              type="button"
              className="transition-all px-4 py-3 mr-2 text-4xl rounded-md shadow-sm font-medium text-facebook bg-facebook bg-opacity-10 hover:bg-facebook hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue rounded-full"
            >
              <a
                href="https://www.facebook.com/jandurasGraphicDesigner"
                rel="noopener noreferrer"
                data-cy="facebookLink"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </button>
            <button
              type="button"
              className="transition-all px-4 py-3 mr-2 text-4xl rounded-md shadow-sm font-medium text-instagram bg-instagram bg-opacity-10 hover:bg-instagram hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue rounded-full"
            >
              <a
                href="https://www.instagram.com/durasjan"
                rel="noopener noreferrer"
                data-cy="instagramLink"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </button>
          </div>

          <p className="text-dark text-opacity-60 font-semibold text-sm mt-7">
            Copyright &copy; 2021 Ján Ďuraš - All rights reserved
          </p>
        </div>
      </footer>
    </main>
  );
};

export default IndexPage;
